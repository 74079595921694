h1,
h2,
h3,
h4,
h5,
h6,
p,
textarea,
pre,
code,
span,
input {
  text-align: right;
}

table {
  margin-left: auto;
}

ul,
ol {
  padding-right: 40px;
  padding-left: initial;
  li {
    direction: rtl;
    text-align: right;
  }
}

.accordion-item {
  text-align: right;
}

.form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.5rem;
  padding-right: 1.5em;
  .form-check-input {
    float: right;
    margin-right: -1.5em;
    &.me-1,
    &.me-2,
    &.me-3 {
      margin-right: -1.5em !important;
    }
  }
  .form-check-label {
    margin-right: 0.5em;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  text-align: right;
}

.input-group {
  &:focus-within .input-group-text {
    border-right: 0;
    border-left: 1px solid;
    border-color: $light-slate;
  }

  :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: 0.25em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.has-validation
    > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
  .dropdown-toggle:nth-last-child(n + 4) {
    border-radius: 0.25em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.text-start {
  text-align: start !important;
}

.ms-auto {
  margin-left: initial !important;
  margin-right: auto !important;
}

.ms-4 {
  margin-left: initial !important;
  margin-right: 1.5rem !important;
}

.me-1 {
  margin-right: initial !important;
  margin-left: 0.25rem !important;
}

.me-2 {
  margin-right: initial !important;
  margin-left: 0.5rem !important;
}

.me-3 {
  margin-right: initial !important;
  margin-left: 0.5rem !important;
}

.ps-3 {
  padding-left: initial !important;
  padding-inline-start: 1rem;
}

.listaflow-rating-interaction-container {
  .rating-number-list {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
  }

  .rating-number-box {
    margin-right: initial;
    margin-left: 10px;
  }
}

.numeric-input {
  margin-left: auto;
}

.dropdown-menu-end {
  &[data-bs-popper] {
    right: auto;
    left: 0;
  }
}
