body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code:before, code:after {
  content: "\00a0";
}

code {
  border-radius: 1rem;
  padding: 3px 0px 3px;
  white-space: nowrap;
  background-color: #2a3a6d;
  color: white;
  font-size: 1.05em;
}

pre code {
  white-space: pre;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}

.accordion-button {
  user-select: text;
}

.accordion-button {
  user-select: text;
}

