// Required
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

// Default variable overrides
$darkest-navy: #020c1b;
$dark-navy: #0a192f;
$navy: #233554;
$light-navy: #4f5d76;
$dark-slate: #8892b0;
$slate: #a8b2d1;
$light-slate: #ccd6f6;
$turquoise: #50ccae;
$bright-turquoise: #64ffda;
$green: #ddf3bf;
$orange: #ef6b73;
$white: #fff;
$black: #000;

$dark: $darkest-navy;

$body-bg: $dark-navy;
$body-color: $light-slate;
$primary: $bright-turquoise;
$secondary: $navy;
$success: $green;
$error: #fecbcb;
$info: #d1fff4;
$danger: $error;
$warning: #fedcba;

$font-family-base: "Poppins", sans-serif;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$h1-font-size: 40px;
$h2-font-size: 37px;
$h3-font-size: 34px;
$h4-font-size: 30px;
$h5-font-size: 26px;
$h6-font-size: 22px;

$font-weight-bold: 600;
$font-weight-bolder: 700;

$btn-padding-y: 14px;
$btn-padding-x: 60px;

$btn-padding-y-sm: 7px;
$btn-padding-x-sm: 5px;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-active-bg-shade-amount: 0;
$btn-active-bg-tint-amount: 0;
$btn-active-border-shade-amount: 0;
$btn-active-border-tint-amount: 0;
$btn-disabled-opacity: 0.25;

$link-color: $bright-turquoise;
$link-decoration: none;

// form checks

$form-check-input-width-xs: 1em;
$form-check-input-width-sm: 1.25em;
$form-check-input-width: 1.5em;
$form-check-input-border-radius: 3px;
$form-check-input-border: 2px solid $dark-slate;
$form-check-input-focus-border: solid 2px $bright-turquoise;
$form-check-input-checked-bg-color: $bright-turquoise;
$form-check-input-checked-color: $darkest-navy;
$form-check-input-bg: transparent;

// Accordion
$accordion-padding-y: 25px;
$accordion-border-width: 2px;
$accordion-border-color: $light-navy;
$accordion-button-focus-border-color: none;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: none;
$accordion-button-active-color: none;
$accordion-icon-color: $light-slate;
$accordion-icon-active-color: $light-slate;
$accordion-icon-transform: none;

// Card
$card-color: $light-slate;
$card-bg: $dark-navy;
$card-border-color: $light-navy;

// Pagination
$pagination-bg: $navy;
$pagination-border-color: $light-navy;

// Progress bar
$progress-height: 3px;
$progress-bg: transparent;

// dropdowns

$dropdown-padding-y: 0;
$dropdown-color: $light-slate;
$dropdown-bg: $navy;
$dropdown-border-color: $light-navy;

$dropdown-link-color: $light-slate;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $light-navy;

$nav-link-color: $dark-slate;
$nav-tabs-link-active-color: $bright-turquoise;

$dropdown-link-active-color: $white;
$dropdown-link-active-bg: $navy;

$dropdown-link-disabled-color: rgba($light-slate, 0.3);

$dropdown-item-padding-y: 15px;
$dropdown-item-padding-x: 13px;

// form inputs
$input-bg: $navy;
$input-color: #ffffff;
$input-border-color: $navy;
$input-placeholder-color: $light-slate;
$input-padding-y: 14px;

$input-focus-bg: $navy;
$input-focus-border-color: $light-slate;
$input-focus-box-shadow: none;

// toasts
$toast-max-width: 500px;
$toast-font-size: 1.0625rem;
$toast-header-color: $darkest-navy;

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Create your own map
$custom-colors: (
  "darkest-navy": $darkest-navy,
  "dark-navy": $dark-navy,
  "navy": $navy,
  "light-navy": $light-navy,
  "dark-slate": $dark-slate,
  "slate": $slate,
  "light-slate": $light-slate,
  "turquoise": $turquoise,
  "bright-turquoise": $bright-turquoise,
  "green": $green,
  "orange": $orange,
);

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  45: $spacer * 2.5,
  5: $spacer * 3,
);

$custom-font-sizes: (
  sm: $font-size-sm,
  xs: $font-size-xs,
);

// Merge the maps
$font-sizes: map-merge($font-sizes, $custom-font-sizes);
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/utilities";
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                90: 90%,
              )
            ),
        )
      ),
  )
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// customize classes
html {
  font-size: 17px;
  &[dir="rtl"] {
    @import "./rtl.scss";
  }
}

img {
  max-width: 100%;
}

.btn {
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.25;
  }
  &:focus,
  &.active {
    opacity: 0.7;
  }
}

small {
  font-size: 12px;
}

.form-control {
  &:disabled {
    opacity: 0.25;
  }
}

.btn-primary {
  color: $darkest-navy;
}

.btn-outline {
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.12);
  }
  &:disabled {
    opacity: 0.25;
  }
}

.nav-link.active {
  color: $bright-turquoise;
}

a {
  &:hover {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0.25;
  }
  &:focus {
    opacity: 0.7;
  }
}

.accordion-item {
  border: none;
  .accordion-button {
    box-shadow: none;
  }
}

.accordion-button {
  &::after {
    transform: rotate(-90deg);
  }
  &:focus {
    &::after {
      filter: drop-shadow(0px 3px 2px $bright-turquoise);
    }
  }
}

.form-check-label {
  margin-bottom: -1px;
  vertical-align: bottom;
  &.white {
    color: $white;
  }
}

.base-icon {
  width: 20px;
  fill: $dark-slate;
  &.path {
    path {
      fill: $dark-slate;
    }
  }
  &.circle {
    circle {
      fill: $dark-slate;
    }
  }
  &.stroke {
    stroke {
      fill: $dark-slate;
    }
  }
  &.line {
    line {
      stroke: $dark-slate;
    }
  }
  &.sm {
    width: 16px;
  }
  &.white {
    fill: $white;
    &.stroke {
      stroke {
        fill: $white;
      }
    }
    &.path {
      path {
        fill: $white;
      }
    }
    &.circle {
      circle {
        fill: $white;
      }
    }
    &.line {
      line {
        stroke: $white;
      }
    }
  }
  &.bright-turquoise {
    fill: $bright-turquoise;
    &.stroke {
      stroke {
        fill: $bright-turquoise;
      }
    }
    &.path {
      path {
        fill: $bright-turquoise;
      }
    }
    &.circle {
      circle {
        fill: $bright-turquoise;
      }
    }
    &.line {
      line {
        fill: $bright-turquoise;
      }
    }
  }
}

.dropdown {
  svg {
    margin-bottom: 4px;
    padding-right: 2px;
  }
  .dropdown-toggle {
    &.circle-active {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;
    }
    .accordion-button {
      padding: 0;
      &::after {
        width: 0.75rem;
        height: 0.75rem;
        background-size: 0.75rem;
        transform: initial;
        margin-left: initial;
      }
      &.large {
        &::after {
          width: 1rem;
          height: 1rem;
          background-size: 1rem;
        }
      }
    }
  }
  &.show {
    .dropdown-toggle {
      &.circle-active {
        background: $light-navy;
        opacity: 1;
      }
      .accordion-button {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.dropdown-item-text {
  &.check-item {
    border-radius: 0.25rem;
    &.checked {
      background-color: $light-navy;
    }
  }
}

a.dropdown-item {
  opacity: 1;
  border-radius: 0.25rem;
  small {
    opacity: 0.6;
  }
  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &:hover {
    .base-icon {
      fill: $white;
      &.line {
        line {
          stroke: $white;
        }
      }
      &.path {
        path {
          fill: $white;
        }
      }
      &.circle {
        circle {
          fill: $white;
        }
      }
      &.stroke {
        stroke {
          fill: $white;
        }
      }
      &.rect {
        rect {
          stroke: $white;
        }
      }
    }
  }
  svg {
    margin-right: 10px;
  }
}

a.white {
  color: $white;
  &:hover,
  &:focus {
    color: $white;
  }
}

.dropdown-toggle {
  &:hover {
    svg {
      fill: $turquoise;
    }
    &.text-reset {
      svg {
        fill: $body-color;
      }
    }
  }
  &:focus {
    svg {
      fill: $bright-turquoise;
    }
    &.text-reset {
      svg {
        fill: $body-color;
      }
    }
  }
  &::after {
    display: none;
  }
}

.dropdown-menu {
  z-index: 1022;
  p {
    padding-left: 13px;
    padding-top: 13px;
    margin-block-end: 0;
    font-size: smaller;
    color: grey;
  }
}

.smiley-icon {
  width: 29px;
  background-color: transparent;
}

.slate-icon {
  width: 18px;
  fill: $slate;
}

.completed-checkmark {
  width: 29px;
  background-color: transparent;
  fill: $bright-turquoise;
}

.hide-toggle-button {
  cursor: default !important;
  &:after {
    display: none;
  }
}

.light-input {
  input,
  span {
    background-color: $light-navy;
    &:focus {
      background-color: $light-navy;
      border-color: $light-slate;
    }
  }
}

.input-group {
  .input-group-text {
    background-color: #3d4d68;
    &.with-border {
      border-color: $dark-slate;
    }
  }

  &:focus-within {
    .input-group-text {
      border: 1px solid $light-slate;
    }
  }
  .form-control {
    border-inline-end: 0;
  }

  .is-invalid {
    border-inline-end: 1px solid $error;
  }

  &.search-bar {
    .input-group-text {
      background-color: $light-navy;
      border-inline-end: 0;
    }
    .form-control {
      background-color: $light-navy;
      border-inline-start: 0;
    }
    &:focus-within {
      .input-group-text {
        border: 1px solid $light-slate;
      }
    }
    .close {
      cursor: pointer;
      svg {
        right: 10px;
      }
    }
  }

  &.switchable {
    &.not-checked {
      .input-group-text {
        background: initial;
        border: 0;
      }
    }
  }
}

.border-focus-end {
  &:focus {
    border-inline-end: 1px solid $light-slate;
  }
}

.btn-link {
  &:focus {
    box-shadow: none;
    text-decoration: underline;
  }
}

.bg-turquoise {
  background-color: $turquoise;
  border-color: $turquoise;
  &:hover {
    background-color: $turquoise;
    border-color: $turquoise;
  }
}

.color-turquoise {
  color: $turquoise;
}

.markdown-editor-navbar {
  border-bottom: 1px solid $light-slate;
}

.markdown-editor-container {
  background-color: $navy;
  padding: 20px;
}

.markdown-editor-tab {
  color: $dark-slate;
  padding: 0 15px 8px 5px;
  font-size: $font-size-sm;
  border-color: rgb($white, 0);
  border-width: 0 0 2px 0;
  background: rgb($white, 0);
  box-sizing: border-box;
}
.markdown-editor-tab:hover {
  color: $white;
}

.markdown-editor-active-tab {
  color: $white;
  border-bottom: 2px solid $white;
}

.markdown-editor-preview {
  padding: 20px;
  color: $white;
  height: 165px;
  overflow: auto;
}
.markdown-editor-textarea {
  background-color: $navy;
  border: 0;
  padding: 20px;
  outline: none;
  color: $white;
  width: 100%;
}

.markdown-editor-textarea::placeholder {
  color: $light-slate;
  opacity: 1;
}

.markdown-editor-help-text {
  font-size: $font-size-sm;
}

.incomplete-warning-border {
  border: 1px solid $danger;
}

.active-rating-box {
  border: 1px solid $light-navy !important;
  background-color: $light-navy !important;
  box-sizing: border-box !important;
  outline: 2px solid $bright-turquoise !important;
}

.rating-number-list li {
  float: left;
}

.rating-number-list {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.btn-rating-number-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $navy;
  width: 70px;
  height: 70px;
  padding: 20px;
  border-radius: 3px;
  color: $white;
  margin-right: 10px;
  transition: background-color 0.5s ease-in-out;
  border: 2px $navy;
  opacity: 1 !important;
  &:hover {
    border: 2px solid $light-navy;
    background-color: $light-navy;
    color: $white;
  }
  &:focus {
    border: 2px solid $white;
    background-color: $navy;
  }
}

.rating-labels-container {
  display: flex;
  justify-content: space-between;
}

.listaflow-rating-interaction-container {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.rating-description-label {
  color: $slate;
  font-size: $font-size-md;
  margin-top: 5px;
  margin-inline-end: 10px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.numeric-input {
  ::placeholder {
    color: rgba($white, 0.5);
  }
  border-radius: 3px;
  width: 216px;
  color: $white;
  .form-control {
    border-left: 2px solid $light-navy;
    border-right: 2px solid $light-navy;
    border-top: 2px solid $navy;
    border-bottom: 2px solid $navy;
    border-radius: 3px !important;
    &:hover {
      border: 2px solid rgba($white, 0.5);
    }
    &:focus-within {
      border: 2px solid rgba($white, 1);
    }
    &.error {
      border: 2px solid rgba($error, 1) !important;
    }
  }
  .btn-numeric {
    width: 59px;
  }
}

.btn-numeric {
  background-color: $navy;
  border: 2px solid $navy;
  color: $bright-turquoise;
  border-radius: 3px;
  &:focus {
    border: 2px solid rgba($white, 1);
    color: $white;
  }
  &:hover {
    border: 2px solid rgba($white, 0.5);
  }
  &:disabled {
    color: rgba($bright-turquoise, 0.3);
    border: 2px solid rgba($navy, 0.5);
    opacity: 0.3;
  }
}

.btn-navbar-dropdown {
  background-color: $navy;
  border: 2px solid $light-navy;
  color: $light-slate;
  border-radius: 3px;
}

.nav-negative-margin {
  margin-top: -8px;
  margin-bottom: -8px;
}
.navbar-dark {
  .navbar-nav {
    .nav-link {
      &.link {
        &.active {
          color: $link-color;
        }
      }
    }
  }
}

.content-divider {
  border: solid 1px $light-navy;
  height: 1px;
  opacity: 1;
}

.spinner-dimensions {
  height: 1.5rem;
  width: 1.5rem;
}

.z-index-sticky {
  z-index: $zindex-sticky;
}

.z-index-1090 {
  z-index: 1090;
}

.top-n1 {
  top: -1px;
}

.password-progress-bar {
  margin-top: -3px;
  position: relative;
  z-index: 3;
}

.form-check-input {
  &:hover {
    border: 2px solid rgba($white, 1);
  }
  &.sm {
    width: $form-check-input-width-sm;
    height: $form-check-input-width-sm;
    vertical-align: middle;
  }
  &.xs {
    width: $form-check-input-width-xs;
    height: $form-check-input-width-xs;
  }

  &.minus {
    &:checked {
      background-image: url("../assets/icons/minus.svg");
    }
  }
}

.confetti {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: none;
}

.checklist-table {
  .heading {
    background: $dark-navy;
    &:before,
    &:after {
      background: $dark-navy;
    }
  }
  .item {
    background-color: $navy;
    > * {
      height: 28px;
      line-height: 28px;
    }
  }
  .unarchive-overlay {
    transition: opacity .2s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: $navy;
    border: 2px solid rgba($white, 1);
    z-index: -1;
    display: flex;
    align-content: center;
    &.activated {
      cursor: pointer;
      opacity: 1;
      z-index: 2;
    }
    .unarchive-label {
      align-self: center;
      text-align: center;
      width: 100%;
    }
  }
}

.mini-avatar {
  > div {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    padding: 0;
    font-size: $font-size-xs;
    line-height: 28px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &.use-border {
      border: 1px solid $navy;
    }
    &.color-0 {
      background-color: #3178ba;
    }
    &.color-1 {
      background-color: #a25599;
    }
    &.color-2 {
      background-color: #7a6975;
    }
    &.color-3 {
      background-color: #b05c00;
    }
    &.color-4 {
      background-color: #297b7b;
    }
  }
  &.fade-last {
    &:last-child {
      mask-image: gradient(
        linear,
        left bottom,
        right bottom,
        from($body-bg),
        to(rgba(0, 0, 0, 0))
      );
      -webkit-mask-image: -webkit-gradient(
        linear,
        left bottom,
        right bottom,
        from($body-bg),
        to(rgba(0, 0, 0, 0))
      );
    }
  }
}

.assignees {
  position: relative;
  .mini-avatar {
    &:not(:first-child) {
      margin-left: -6px;
    }
  }
  .full {
    width: 205px;
    max-height: 205px;
    position: absolute;
    z-index: 1;
    display: none;
    background-color: $white;
    color: $dark-navy;
    top: 28px;
    overflow-y: scroll;
  }
  &:hover {
    .full {
      display: block;
    }
  }
}

.scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &.black-scrollbar {
    ::-webkit-scrollbar {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: $white;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $body-color;
    }
    ::-webkit-scrollbar-thumb:window-inactive {
      background: $black;
    }
  }

  &.light-scrollbar {
    ::-webkit-scrollbar {
      background: $navy;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $body-color;
    }
    ::-webkit-scrollbar-thumb:window-inactive {
      background: $slate;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.filter {
  &.scroll {
    .dropdown-menu {
      max-height: 389px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  .dropdown-menu {
    width: 313px;
  }
}

.circle-text {
  width: 20px;
  height: 20px;
  background-color: $light-navy;
  border-radius: 50%;
  > * {
    display: inline-block;
    line-height: 20px;
    vertical-align: top;
  }
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.checklist-status {
  color: $black;
  white-space: nowrap;
  > * {
    border-radius: 1.25rem;
    padding: 0.25em 0.75em;
  }
  &.completed {
    > * {
      background-color: $success;
    }
  }

  &.to_do {
    > * {
      background-color: $info;
    }
  }
  &.past_due {
    > * {
      background-color: $warning;
    }
  }

  &.up_coming {
    > * {
      background-color: $primary;
    }
  }

  &.in_progress {
    > * {
      background-color: $white;
    }
  }
}

.table-fixed {
  width: 100%;
  --radius: 10px;
  border: solid 1px $dark-navy;
  border-radius: var(--radius);
  overflow: hidden;
  border-spacing: 0;
  thead {
    background-color: $dark-slate;
    tr {
      border-color: $dark-navy;
    }
    color: black;
    font-size: 14px;
    letter-spacing: 0.18px;
    text-align: center;
    font-weight: normal;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border: solid 1px $dark-navy;
  }
  .scollable-body {
    display: block;
    overflow-y: auto;
    max-height: 500px;
  }
  tbody {
    display: table;
    table-layout: fixed;
    background-color: $navy;
    border-top: none !important;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: normal;
    text-align: center;
    font-weight: normal;
    td {
      border-width: 0px 1px 0px 0px;
    }
    td:nth-child(1) {
      background-color: #2e3f5c;
    }
  }

  .table-row-clipped {
    .markdown-table-cell {
      display: block !important;
      max-height: 150px;
      overflow: hidden;
      mask-image: linear-gradient(white 70%, transparent 100%);
      -webkit-mask-image: linear-gradient(white 70%, transparent 100%);
    }
  }
}
.text-dark-slate {
  color: $dark-slate;
}

.text-light-slate {
  color: $light-slate;
}

.fs-7 {
  font-size: 17px;
}

.fs-8 {
  font-size: 14px;
}

.first-letter-bold::first-letter {
  font-weight: 600 !important;
}

.bg-light-slate {
  background-color: $light-slate;
}

.bg-light-navy {
  background-color: $light-navy;
}

.border-light-navy {
  border-color: $light-navy;
}

.download-small-button {
  button {
    padding: 0px !important;
    border: 0 !important;
  }
}

.react-select-container {
  font-size: 14px;
  .react-select__control {
    background-color: $navy;
    border: 1px solid $light-navy;
    transition: none;
    color: $light-navy;
    min-height: 40px;
  }

  .react-select__control--is-focused {
    border: 1px solid $light-slate !important;
    box-shadow: none !important;
  }

  .react-select__menu {
    background-color: $navy;
    border: 1px solid $light-navy;
  }

  .react-select__option {
    background-color: $navy;
    color: $light-slate;
    &:hover {
      background-color: rgba($dark-slate, 0.6);
      color: #fff;
    }
    &:focus {
      background-color: rgba($light-navy, 0.6);
      color: #fff;
    }
  }

  .react-select__option--is-focused {
    background-color: rgba($light-navy, 0.6);
  }

  .react-select__clear-indicator {
    color: $light-navy;
  }
  .react-select__indicator-separator {
    background-color: $light-navy;
    margin-top: 0;
    margin-bottom: 0;
  }

  .react-select__single-value {
    color: #fff;
  }

  .react-select__placeholder {
    color: $dark-slate;
  }

  .react-select__input {
    color: #fff !important;
  }
}

.popup-date-range-button-group {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid $light-slate;
  border-radius: 3px;
  overflow: hidden;
}

.dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
}

.customCalendarWrapper {
  border: solid 1px $light-slate;
  .rdrCalendarWrapper {
    background-color: $navy !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrDefinedRangesWrapper {
    background-color: $navy !important;
    border: solid 1px rgba(white, 0.2);
    padding: 20px;
  }
  .rdrStaticRanges {
    border: 0;
  }
  .rdrStaticRange {
    background-color: $navy !important;
    color: $light-slate !important;
    font-size: $font-size-sm;
    border: 0;
    &:hover,
    &:focus {
      .rdrStaticRangeLabel {
        background-color: rgba(79, 93, 118, 0.6) !important;
        color: $light-slate !important;
        font-weight: normal;
      }
    }
  }
  .rdrStaticRangeSelected {
    background-color: rgba(79, 93, 118, 0.6) !important;
    color: $light-slate !important;
    font-weight: normal !important;
  }
  .rdrDays {
    font-size: $font-size-sm;
    color: $light-slate !important;
    .rdrDayPassive {
      visibility: hidden;
    }
    .rdrDayNumber {
      span {
        color: $light-slate !important;
      }
    }
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: $dark-slate;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }

  .rdrInRange ~ .rdrDayNumber,
  .rdrStartEdge ~ .rdrDayNumber,
  .rdrEndEdge ~ .rdrDayNumber {
    span {
      color: $dark-navy !important;
    }
  }

  .rdrSelected {
    left: 2px;
    right: 2px;
  }

  .rdrStartEdge {
    background-color: $light-slate;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 2px;
  }

  .rdrEndEdge {
    background-color: $light-slate;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    right: 2px;
  }

  .rdrSelected {
    border-radius: 4px;
  }

  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek {
    .rdrInRange,
    .rdrEndEdge {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 2px;
    }
  }

  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek {
    .rdrInRange,
    .rdrStartEdge {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      right: 2px;
    }
  }

  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek {
    .rdrDayInPreview,
    .rdrDayEndPreview {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left-width: 1px;
      left: 0px;
    }
  }

  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek {
    .rdrDayInPreview,
    .rdrDayStartPreview {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right-width: 1px;
      right: 0px;
    }
  }

  .rdrDayStartPreview {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rdrDayEndPreview {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rdrMonthName {
    font-size: $h6-font-size;
    color: white;
    line-height: 1.45;
    font-weight: normal;
    text-align: center;
  }

  .rdrNextPrevButton {
    border: solid 1px $dark-slate;
    background-color: $navy !important;
    width: 35px;
    height: 35px;
  }
  .rdrPprevButton {
    i {
      border-width: 8px 8px 8px 4px;
      border-right-color: $dark-slate;
    }
  }
  .rdrNextButton {
    i {
      border-width: 8px 4px 8px 8px;
      border-left-color: $dark-slate;
    }
  }
  .rdrMonthAndYearPickers {
    font-weight: normal;
    select {
      color: $light-slate;
      background-color: $light-slate;
      background: url("data:image/svg+xml;utf8,<svg width='10px' height='7px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='white' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
        no-repeat;
      background-position: right 8px center;
    }
  }
  .rdrWeekDays {
    border-bottom: solid 1px rgba(white, 0.2);
  }
  .rdrWeekDay {
    font-weight: normal;
    line-height: 3.33;
    color: $slate;
  }
}

.no-hover {
  &:hover,
  &:focus {
    color: currentColor;
  }
}

.btn-pill-check {
  border-radius: 26px !important;
  border: solid 1px $light-navy;
  color: $slate;
  &:hover {
    background-color: rgba($color: $bright-turquoise, $alpha: 0.1);
    color: $white !important;
    border: 1px solid $bright-turquoise;
  }
  &:focus {
    box-shadow: 0 0 0 1px $bright-turquoise;
  }
}

.btn-check:checked + .btn-outline-primary {
  background-color: rgba($color: $bright-turquoise, $alpha: 0.1);
  color: $white !important;
  border: 1px solid $bright-turquoise;
  .base-icon {
    display: inline-block !important;
  }
}

.run-listing-item {
  position: relative;
  a.masked-link {
    color: $white;
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
  a.link-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 0;
    &:hover {
      border: solid 1px $light-slate;
    }
    &:focus {
      border: solid 2px $light-slate;
    }
    &:focus-visible {
      border: solid 2px $light-slate;
      outline: none;
    }
    &:active {
      border: solid 1px rgba($light-slate, 0.07);
    }
  }
  .interaction {
    position: relative;
    z-index: 1;
  }
}

.checklist-subsection {
  & > .accordion-button {
    color: #fff;
  }

  .accordion-body {
    padding-top: 0;
    padding-bottom: 0;

    .accordion-item {
      border-width: 1px !important;

      &:first-child {
        border-top: 0 !important;
      }
    }
  }
}

.checklist-required-marker {
  font-size: 22px;
  color: #64ffda;
  position: absolute;
  left: 0;
}
